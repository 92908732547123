import posed from 'react-pose';

const transition = {
  duration: 450,
};

export const MenuItem = posed.div({
  open: {
    x: 0,
    opacity: 1,
  },
  closed: {
    x: 100,
    opacity: 0,
  },
});

export const Icon = posed.div({
  open: {
    x: 20,
    opacity: 1,
  },
  closed: {
    x: 0,
    opacity: 0,
  },
});

export const MenuContainer = posed.div({
  open: {
    applyAtStart: { display: 'block' },
    opacity: 1,
    transition,
    delayChildren: 200,
    staggerChildren: 50,
  },
  closed: {
    applyAtEnd: { display: 'none' },
    opacity: 0,
    transition,
  },
});

export const Bar1 = posed.div({
  closed: {
    rotate: 0,
    x: '0',
    y: '0',
    transition,
  },
  open: {
    rotate: '45deg',
    x: '4px',
    y: '-1px',
    transition,
  },
});

export const Bar2 = posed.div({
  closed: {
    opacity: 1,
    transition,
  },
  open: {
    transform: 'none',
    opacity: 0,
  },
});

export const Bar3 = posed.div({
  closed: {
    rotate: 0,
    x: '0',
    y: '0',
    transition,
  },
  open: {
    rotate: '-45deg',
    x: '2px',
    y: '3px',
    transition,
  },
});
