import { graphql, useStaticQuery } from "gatsby"

const useMvpPopup = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulMvpPopup {
        title
        isEnabled
        clientLogos {
          title
          file {
            url
          }
        }
        contentCards {
          title
          buttonType
          buttonLink
          buttonLabel
        }
      }
    }
  `)

  return data.contentfulMvpPopup
}

export default useMvpPopup
