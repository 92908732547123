import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import "./style.scss"

import { MenuItem } from "./header-animations"
import { getWrapperLinkStyle } from "./header-utils"

const MobileItemWithSubItems = ({ subitem, setMobileMenuOpened }) => {
  const [isActive, setIsActive] = useState(false)
  const id = subitem.name

  useEffect(() => {
    const onMenuItemClick = (event) => {
      const current = event.detail

      if (current !== id) {
        setIsActive(false)
      }
    }
    document.addEventListener("onSubMenuItemClick", onMenuItemClick)

    return () => {
      document.removeEventListener("onSubMenuItemClick", onMenuItemClick)
    }
  }, [id])

  return (
    <div className="subitemsWrapperMob subitem">
      <MenuItem
        onClick={() => {
          setIsActive(!isActive)
          const event = new CustomEvent("onSubMenuItemClick", { detail: id })
          document.dispatchEvent(event)
        }}
        className={`linksWrapperMob subitem`}
        key={subitem.name}
      >
        <div
          className={isActive ? "activeCategoryLink" : "activeCategoryNoLink"}
        >
          {subitem.name}
          <span
            className={isActive ? "arrowMobile" : "arrowMobileTransform"}
          ></span>
        </div>
      </MenuItem>
      {isActive &&
        subitem.items.map((item) => {
          const currentPath = window.location.pathname
          const finalUrl = item.url.startsWith(`${currentPath}#`)
            ? item.url.substr(currentPath.length)
            : item.url
          const urlWasChanged = finalUrl !== item.url

          return (
            <MenuItem
              onClick={() => {
                setIsActive(!isActive)
                setMobileMenuOpened(false)
              }}
              className={`linksWrapperMob subitems ${getWrapperLinkStyle(
                item.url
              )}`}
              key={item.name}
            >
              {item.externalUrl ? (
                <a className="links" href={item.externalUrl}>
                  {item.name}
                </a>
              ) : (
                <Link
                  className="links"
                  href={urlWasChanged ? finalUrl : undefined}
                  to={urlWasChanged ? undefined : finalUrl}
                >
                  {item.name}
                </Link>
              )}
            </MenuItem>
          )
        })}
    </div>
  )
}

export default MobileItemWithSubItems
