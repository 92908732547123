import { graphql, useStaticQuery } from 'gatsby';

const useHeaderData = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      contentfulV4MenuLetsTalk {
        menus {
          name
          url
          externalUrl
          id
          subitems {
            name
            url
            items {
              url
              externalUrl
              name
            }
          }
        }
        socialProfiles {
          name
          url
          icon
        }
        grioEmail
        grioOffices {
          address1
          address2
          name
        }
        grioPhoneNumber
      }
    }
  `);

  return data;
};

export default useHeaderData;
