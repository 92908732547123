import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Header from "../components/header"
import "./index.scss"
import FloatingFooter from "../components/floating-footer"
import MvpModal from "../components/mvp-modal"
import useMvpPopup from "../hooks/useMvpPopup"
import { globalHistory } from "@reach/router"

const SHOW_MVP_MODAL_TIMER = 10000

const Layout = ({ children }) => {
  const { isEnabled } = useMvpPopup()

  const [showExitModal, setShowExitModal] = useState(false)

  const handleCloseModal = () => {
    setShowExitModal(false)
  }

  useEffect(() => {
    let timer

    const setFreshTimeout = () => {
      const timeout = setTimeout(() => {
        setShowExitModal(shouldShowModal())
        timer = null
      }, SHOW_MVP_MODAL_TIMER)

      return timeout
    }

    timer = setFreshTimeout()

    const shouldShowModal = () => {
      const strippedPath = window.location.pathname.replace(/\//g, "")
      return ![
        "mvp-webinar",
        "book-a-free-consult",
        "contact-us",
        "book-mvp-blueprint",
      ].includes(strippedPath)
    }

    globalHistory.listen(({ action }) => {
      if (action === "PUSH" || action === "POP") {
        if (timer) {
          clearTimeout(timer)
          timer = setFreshTimeout()
        }
      }
    })

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      {showExitModal && isEnabled && (
        <MvpModal handleCloseModal={handleCloseModal} />
      )}
      <Header />
      <div className="mainWrapper">
        <main>{children}</main>
      </div>
      <FloatingFooter />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
