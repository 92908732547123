import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import ArrowDown from "../../../images/arrow_down.inline.svg"
import ArrowUp from "../../../images/arrow_up.inline.svg"
import "../style.scss"
import * as headerStyle from "../style.module.scss"
import { getLinkStyle } from "../header-utils"

const DesktopMenuItem = ({ menu, openMenu, index, openPopover, closePopover }, ref) => {
  const [isMenuOpen, setIsMenuOpen] = useState(openMenu?.id === menu.id)

  useEffect(() => {
    setIsMenuOpen(openMenu?.id === menu.id)
  }, [openMenu, menu])

  const onMouseEnter = () => {
    setIsMenuOpen(true)
    openPopover(menu)
  }

  const RootMenuLink = (
    <>
      {menu.name.toLocaleLowerCase()}
      {isMenuOpen ? (
        <ArrowUp className={headerStyle.arrow} />
      ) : (
        <ArrowDown className={headerStyle.arrow} />
      )}
    </>
  )

  return (
    <div className={`linksWrappers`} ref={ref}>
      {menu.externalUrl ? (
        <a
          onMouseEnter={onMouseEnter}
          onMouseLeave={closePopover}
          onMouseDown={closePopover}
          className="links"
          href={menu.externalUrl}
        >
          {RootMenuLink}
        </a>
      ) : (
        <Link
          onMouseEnter={onMouseEnter}
          onMouseLeave={closePopover}
          onMouseDown={closePopover}
          className={getLinkStyle(menu.url)}
          to={menu.url}
        >
          {RootMenuLink}
        </Link>
      )}
    </div>
  )
}

export default React.forwardRef(DesktopMenuItem)
