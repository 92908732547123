// extracted by mini-css-extract-plugin
export var arrow = "style-module--arrow--024f4";
export var desktopHeaderContainer = "style-module--desktopHeaderContainer--911b2";
export var desktopMainMenu = "style-module--desktopMainMenu--1d53c";
export var itemsWrapper = "style-module--itemsWrapper--6cb0f";
export var logoContainer = "style-module--logoContainer--cc2b3";
export var menuContainer = "style-module--menuContainer--3540c";
export var menuWrapper = "style-module--menuWrapper--be7b2";
export var mobileMainMenu = "style-module--mobileMainMenu--cbe66";
export var multiList = "style-module--multiList--4efe7";
export var singleList = "style-module--singleList--d7955";
export var subMenuBackground = "style-module--subMenuBackground--f93e2";
export var subMenuContainer = "style-module--subMenuContainer--369e1";
export var subitemName = "style-module--subitemName--a826f";
export var subitemWrapper = "style-module--subitemWrapper--68d8a";