import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import "./style.scss"
import { MenuItem } from "./header-animations"
import { getWrapperLinkStyle } from "./header-utils"
import MobileItemWithSubItems from "./mobile-item-with-subitems"

const MobileItem = ({ currentMenu, setMobileMenuOpened }) => {
  const [isActive, setIsActive] = useState(false)
  const id = currentMenu.name
  const mobileMenuOpened = useState(false)

  useEffect(() => {
    const onMenuItemClick = (event) => {
      const current = event.detail

      if (current !== id) {
        setIsActive(false)
      }
    }
    document.addEventListener("onMenuItemClick", onMenuItemClick)

    return () => {
      document.removeEventListener("onMenuItemClick", onMenuItemClick)
    }
  }, [id])

  return (
    <>
      <MenuItem
        onClick={() => {
          setIsActive(!isActive)
          const event = new CustomEvent("onMenuItemClick", { detail: id })
          document.dispatchEvent(event)
        }}
        className={`linksWrapperMob linksWrapperMobStyle ${getWrapperLinkStyle(
          currentMenu.url
        )}`}
        key={currentMenu.name}
      >
        <div className={isActive ? "activeCategoryLink" : "activeCategoryNoLink"}>
          {currentMenu.name}
          <span className={isActive ? "arrowMobile" : "arrowMobileTransform"}
          ></span>
        </div>
      </MenuItem>
      {isActive &&
        currentMenu.subitems.map((subitem) => {
          if (subitem.name) {
            return (
              <MobileItemWithSubItems
                subitem={subitem}
                setMobileMenuOpened={setMobileMenuOpened}
              />
            )
          }

          return (
            <div className="subitemsWrapperMob subitemsWrapperMobStyle">
              {subitem.items.map((item) => {
                const currentPath = window.location.pathname
                const finalUrl = item.url.startsWith(`${currentPath}#`)
                  ? item.url.substr(currentPath.length)
                  : item.url
                const urlWasChanged = finalUrl !== item.url

                return (
                  <MenuItem
                    onClick={() => {
                      setIsActive(!isActive)
                      setMobileMenuOpened(false)
                    }}
                    className={`linksWrapperMob ${getWrapperLinkStyle(
                      item.url
                    )}`}
                    key={item.name}
                  >
                    {item.externalUrl ? (
                      <a className="links" href={item.externalUrl}>
                        {item.name}
                      </a>
                    ) : (
                      <Link
                        className="links"
                        href={urlWasChanged ? finalUrl : undefined}
                        to={urlWasChanged ? undefined : finalUrl}>{item.name}
                      </Link>
                    )}
                  </MenuItem>
                )
              })}
            </div>
          )
        })}
    </>
  )
}

export default MobileItem
