exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-android-app-development-js": () => import("./../../../src/pages/android-app-development.js" /* webpackChunkName: "component---src-pages-android-app-development-js" */),
  "component---src-pages-app-development-js": () => import("./../../../src/pages/app-development.js" /* webpackChunkName: "component---src-pages-app-development-js" */),
  "component---src-pages-book-a-free-consult-js": () => import("./../../../src/pages/book-a-free-consult.js" /* webpackChunkName: "component---src-pages-book-a-free-consult-js" */),
  "component---src-pages-book-consult-thank-you-js": () => import("./../../../src/pages/book-consult-thank-you.js" /* webpackChunkName: "component---src-pages-book-consult-thank-you-js" */),
  "component---src-pages-book-mvp-blueprint-js": () => import("./../../../src/pages/book-mvp-blueprint.js" /* webpackChunkName: "component---src-pages-book-mvp-blueprint-js" */),
  "component---src-pages-book-mvp-thank-you-js": () => import("./../../../src/pages/book-mvp-thank-you.js" /* webpackChunkName: "component---src-pages-book-mvp-thank-you-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-healthcare-app-development-js": () => import("./../../../src/pages/healthcare-app-development.js" /* webpackChunkName: "component---src-pages-healthcare-app-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ios-app-development-js": () => import("./../../../src/pages/ios-app-development.js" /* webpackChunkName: "component---src-pages-ios-app-development-js" */),
  "component---src-pages-iot-app-development-js": () => import("./../../../src/pages/iot-app-development.js" /* webpackChunkName: "component---src-pages-iot-app-development-js" */),
  "component---src-pages-join-grio-js": () => import("./../../../src/pages/join-grio.js" /* webpackChunkName: "component---src-pages-join-grio-js" */),
  "component---src-pages-medical-app-development-js": () => import("./../../../src/pages/medical-app-development.js" /* webpackChunkName: "component---src-pages-medical-app-development-js" */),
  "component---src-pages-mobile-app-development-js": () => import("./../../../src/pages/mobile-app-development.js" /* webpackChunkName: "component---src-pages-mobile-app-development-js" */),
  "component---src-pages-mvp-app-development-js": () => import("./../../../src/pages/mvp-app-development.js" /* webpackChunkName: "component---src-pages-mvp-app-development-js" */),
  "component---src-pages-mvp-blueprint-js": () => import("./../../../src/pages/mvp-blueprint.js" /* webpackChunkName: "component---src-pages-mvp-blueprint-js" */),
  "component---src-pages-mvp-webinar-js": () => import("./../../../src/pages/mvp-webinar.js" /* webpackChunkName: "component---src-pages-mvp-webinar-js" */),
  "component---src-pages-our-company-js": () => import("./../../../src/pages/our-company.js" /* webpackChunkName: "component---src-pages-our-company-js" */),
  "component---src-pages-our-reviews-js": () => import("./../../../src/pages/our-reviews.js" /* webpackChunkName: "component---src-pages-our-reviews-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-web-app-development-js": () => import("./../../../src/pages/web-app-development.js" /* webpackChunkName: "component---src-pages-web-app-development-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/case-studies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-specializations-js": () => import("./../../../src/templates/specializations.js" /* webpackChunkName: "component---src-templates-specializations-js" */)
}

