import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"

import * as styles from './styles.module.scss';

const SecondaryButton = ({ to, children, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  }

  return (
    <Link to={to} className={styles.secondaryButton} onClick={handleClick}>
      {children}
    </Link>
  );
};

SecondaryButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SecondaryButton;
