import React, { useState, useMemo } from "react"
import { Link } from "gatsby"

import * as headerStyle from "./style.module.scss"
import "./style.scss"
import Logo from "../../images/logo.inline.svg"
import { getIcon } from "./header-utils"
import { Icon, MenuContainer, Bar1, Bar2, Bar3 } from "./header-animations"
import MobileItem from "./mobile-item"
import PrimaryButton from "../primary-button"

const MobileHeader = ({ data }) => {
  const menuButtons = data.contentfulV4MenuLetsTalk.menus.filter(
    (currentMenu) => {
      return (
        currentMenu.url.includes("book-a-free-consult")
      )
    }
  )
  const menuLinks = useMemo(() => {
    return data.contentfulV4MenuLetsTalk.menus.filter((menu) => {
      return (
        !menu.url.includes("book-a-free-consult") 
      )
    })
  }, [data])

  const letsTalkButton = menuButtons.find((currentMenu) =>
    currentMenu.url.includes("book-a-free-consult")
  )

  const [mobileMenuOpened, setMobileMenuOpened] = useState(false)

  return (
    <div className="mobile-header">
      <div className={headerStyle.mobileMainMenu}>
        <div className={headerStyle.logoContainer}>
          <Link to={"/"} onClick={() => setMobileMenuOpened(false)} alt="Home">
            <Logo className="logo" />
          </Link>
        </div>
        <div
          className={
            mobileMenuOpened ? "primaryButtonShow" : "primaryButtonHide"
          }
        >
          <PrimaryButton
            onClick={() => setMobileMenuOpened(false)}
            to={letsTalkButton.url}
          >
            {letsTalkButton.name}
          </PrimaryButton>
        </div>
        <div
          className="hamburgerMenu"
          onClick={() => {
            setMobileMenuOpened(!mobileMenuOpened)
          }}
        >
          <Bar1
            className="bar"
            key="b1"
            pose={mobileMenuOpened ? "open" : "closed"}
          />
          <Bar2
            className="bar"
            key="b2"
            pose={mobileMenuOpened ? "open" : "closed"}
          />
          <Bar3
            className="bar"
            key="b3"
            pose={mobileMenuOpened ? "open" : "closed"}
          />
        </div>
      </div>
      <div className="menuMobileContainer">
        <MenuContainer
          className="menuMobile"
          pose={mobileMenuOpened ? "open" : "closed"}
        >
          {data.contentfulV4MenuLetsTalk.menus.map((currentMenu) => {
            if (
              currentMenu.url.includes("book-a-free-consult")
            ) {
              return null
            }

            return (
              <MobileItem
                currentMenu={currentMenu}
                setMobileMenuOpened={setMobileMenuOpened}
                key={currentMenu.id}
              />
            )
          })}
          <div className="mobileContainer">
            <Icon className="socialNetworks">
              {data.contentfulV4MenuLetsTalk.socialProfiles.map((current) => (
                <a
                  key={current.name}
                  href={current.url}
                  alt={current.name}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getIcon(current.icon)}
                </a>
              ))}
            </Icon>
          </div>
        </MenuContainer>
      </div>
    </div>
  )
}

export default MobileHeader
