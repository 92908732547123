import React, { useContext } from "react"

import { HomeContext } from "../../hooks/HomeContext"
import PrimaryButton from "../primary-button"
import * as styles from "./styles.module.scss"

const FloatingFooter = () => {
  const homeData = useContext(HomeContext)

  return (
    <>
      <div className={styles.floatingFooterContainer}>
        <PrimaryButton to={`/book-a-free-consult`} >
          <span style={{"padding" : "0 60px", "fontWeight" : "bold"}}>Let's Talk</span>
        </PrimaryButton>
      </div>
      <span className="pageClass">{homeData.keywords}</span>
    </>
  )
}

export default FloatingFooter
