import React, { useMemo, useRef, useState } from "react"
import { Link } from "gatsby"

import Logo from "../../../images/logo.inline.svg"
import "../style.scss"
import * as headerStyle from "../style.module.scss"
import DesktopMenuItem from "./desktop-menu-item"
import PrimaryButton from "../../primary-button"
import SubmenuItem from "./desktop-submenu-item"

const DesktopHeader = ({ data }) => {
  const [currentMenu, setCurrentMenu] = useState(null)
  const hasMouseInside = useRef(false)
  const menuItemRefs = useRef([])
  const currentMenuRef = useRef(null)

  const openPopover = (menu) => {
    setCurrentMenu(menu)
    hasMouseInside.current = true
    const menuItemRef = menuItemRefs.current.find((item) => item.id === menu.id)
    if (menuItemRef) {
      currentMenuRef.current = menuItemRef.ref
    }
  }

  const closePopover = () => {
    hasMouseInside.current = false
    setTimeout(() => {
      if (!hasMouseInside.current) {
        setCurrentMenu(null)
      }
    }, 200)
  }

  const menuButtons = data.contentfulV4MenuLetsTalk.menus.filter(
    (currentMenu) => {
      return (
        currentMenu.url.includes("book-a-free-consult")
      )
    }
  )
  const menuLinks = useMemo(() => {
    return data.contentfulV4MenuLetsTalk.menus.filter((menu) => {
      return (
        !menu.url.includes("book-a-free-consult")
      )
    })
  }, [data])

  const letsTalkButton = menuButtons.find((currentMenu) =>
    currentMenu.url.includes("book-a-free-consult")
  )

  return (
    <div className={headerStyle.desktopHeaderContainer}>
      <div className={headerStyle.desktopMainMenu}>
        <div style={{display: "flex"}}>
          <div className={headerStyle.logoContainer}>
            <Link to={"/"} alt="Home">
              <Logo className="logo" />
            </Link>
          </div>
          <div className={headerStyle.menuContainer}>
            {menuLinks.map((menu, idx) => (
                <DesktopMenuItem
                  key={`item-desktop-${idx}`}
                  menu={menu}
                  openMenu={currentMenu}
                  openPopover={openPopover}
                  closePopover={closePopover}
                  ref={(el) => menuItemRefs.current.push({ id: menu.id, ref: el })}
                />
              ))}
          </div>
        </div>
        <div className={headerStyle.menuContainer}>
          <div className="linksWrappers contact">
            <PrimaryButton to={letsTalkButton.url}>
              {letsTalkButton.name}
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className={headerStyle.subMenuBackground}>
        <div
          className={headerStyle.subMenuContainer}
          style={{
            left:
              currentMenu && currentMenuRef.current
                ? currentMenuRef.current.getBoundingClientRect().left
                : 0,
          }}
        >
          {currentMenu &&
            currentMenu.subitems.map((subitem, idx) => (
              <div
                className={headerStyle.menuWrapper}
                onMouseEnter={() => openPopover(currentMenu)}
                onMouseLeave={closePopover}
                key={idx}
              >
                <SubmenuItem
                  subitem={subitem}
                  closePopover={closePopover}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default DesktopHeader
