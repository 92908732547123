import React, { useEffect, useState } from "react"

import useMvpPopup from "../../hooks/useMvpPopup"
import * as styles from "./styles.module.scss"
import PrimaryButton from "../primary-button"
import SecondaryButton from "../secondary-button"
import desktopHexLeft from "../../images/hexagon_backgrounds/mvp-modal/desktop_left.png"
import desktopHexRight from "../../images/hexagon_backgrounds/mvp-modal/desktop_right.png"
import DismissIcon from "../../images/dismiss.inline.svg"
import { useMediaQuery } from "react-responsive"

const MvpModal = ({ handleCloseModal }) => {
  const { title, clientLogos, contentCards } = useMvpPopup()
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
  const [logoUrl, setLogoUrl] = useState("")

  useEffect(() => {
    if (clientLogos) {
      const logos = clientLogos.reduce((acc, { title, file: { url } }) => {
        acc[title] = url
        return acc
      }, {})

      const clientsLogosUrl = isMobile
        ? logos.clientLogosMobile
        : logos.clientsLogosDesktop

      setLogoUrl(clientsLogosUrl)
    }
  }, [clientLogos, isMobile])

  const ContentCard = (card) => {
    const { title, buttonType, buttonLabel, buttonLink } = card

    const button =
      buttonType === "primary" ? (
        <PrimaryButton to={buttonLink} onClick={handleCloseModal}>{buttonLabel}</PrimaryButton>
      ) : (
        <SecondaryButton to={buttonLink} onClick={handleCloseModal}>{buttonLabel}</SecondaryButton>
      )

    return (
      <div className={styles.card}>
        <h6>{title}</h6>
        {button}
      </div>
    )
  }

  return (
    <div onClick={handleCloseModal} className={styles.container}>
      <div onClick={(e) => e.stopPropagation()} className={styles.modal}>
        <DismissIcon
          className={styles.dismissIcon}
          onClick={handleCloseModal}
        />
        <h1>{title}</h1>
        <img src={logoUrl} className={styles.clientsImage} alt="Our Clients" />
        <div className={styles.content}>
          {contentCards.map((card, index) => (
            <ContentCard key={index} {...card} />
          ))}
        </div>
        <img src={desktopHexLeft} alt="Hexagon" className={styles.hexLeft} />
        <img src={desktopHexRight} alt="Hexagon" className={styles.hexRight} />
      </div>
    </div>
  )
}

export default MvpModal
