import React from 'react';
import {
  FaLinkedinIn,
  FaFacebookF,
  FaAmilia,
  FaInstagram,
} from "react-icons/fa";

export const getLinkStyle = (url) => {
  if (url.includes('book-a-free-consult')) {
    return 'letsTalkButton';
  }

  return 'links';
}

export const getWrapperLinkStyle = (url) => {
  if (url.includes('book-a-free-consult')) {
    return 'letsTalkButtonWrapper';
  }

  return '';
}

export const getIcon = (iconFromContentful = "", url = "") => {
  switch (iconFromContentful) {
    case "fb":
      return <FaFacebookF />
    case "linkedin":
      return <FaLinkedinIn />
    case "instagram":
      return <FaInstagram />
    default:
      return <FaAmilia />
  }
}
