import React from "react"
import "./style.scss"
import DesktopHeader from "./desktop/desktop-header"
import MobileHeader from "./mobile-header"
import useHeaderData from "./use-header-data"

const Header = () => {
  const data = useHeaderData()

  return (
    <div key="mainHeader" className='headerContainer'>
      <DesktopHeader data={data} />
      <MobileHeader data={data} />
    </div>
  )
}

export default Header
