import React from "react"
import { Link } from "gatsby"

import * as headerStyle from "../style.module.scss"
import { getLinkStyle } from "../header-utils"

const SubmenuItem = ({ subitem, closePopover }) => (
  <div className={headerStyle.subitemWrapper}>
    {subitem.name && (
      <div className={headerStyle.subitemName}>
        {subitem.name && subitem.url ? (
          <Link to={subitem.url} onMouseDown={closePopover}>
            {subitem.name}
          </Link>
        ) : (
          <p>{subitem.name}</p>
        )}
      </div>
    )}
    <ul
      className={
        subitem.items.length === 1
          ? headerStyle.singleList
          : headerStyle.multiList
      }
    >
      {subitem.items.map((item) => {
        const currentPath = window.location.pathname
        const finalUrl = item.url.startsWith(`${currentPath}#`)
          ? item.url.substr(currentPath.length)
          : item.url
        const urlWasChanged = finalUrl !== item.url

        return (
          <li key={item.name}>
            {item.externalUrl ? (
              <a
                className="links"
                href={item.externalUrl}
                onMouseDown={closePopover}
              >
                {item.name}
              </a>
            ) : (
              <Link
                className={getLinkStyle(item.url)}
                onMouseDown={closePopover}
                href={urlWasChanged ? finalUrl : undefined}
                to={urlWasChanged ? undefined : finalUrl}
              >
                {item.name}
              </Link>
            )}
          </li>
        )
      })}
    </ul>
  </div>
)

export default SubmenuItem
